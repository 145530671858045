@layer tailwind {
  @tailwind base;
}

@tailwind components;
@tailwind utilities;


:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}


.mobile-hero-section-background {
  --color-start: rgba(8, 62, 129, 1);
  --color-end: rgba(16, 66, 100, 0.5);
  background:

    radial-gradient(circle at 50% 50%, rgb(18, 105, 139), transparent 90%),
    linear-gradient(to right, rgb(20, 46, 78), rgba(4, 37, 60, 0.96));
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: visible;
  z-index: 50;
}

.hero-section-background {
  --color-start: rgba(8, 62, 129, 1);
  --color-end: rgba(16, 66, 100, 0.5);
  background:
    radial-gradient(circle at 80% 50%, rgba(18, 127, 139, 0.1), transparent 60%),
    radial-gradient(circle at 80% 50%, rgb(18, 105, 139), transparent 50%),
    linear-gradient(to right, rgb(20, 46, 78), rgba(4, 37, 60, 0.96));
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: visible;
  z-index: 50;
}

.hero-section-background::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 51;
  mix-blend-mode: normal;
  opacity: 0.2;
  background: none;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none"><filter id="film-grain"><feTurbulence type="fractalNoise" baseFrequency="0.9" numOctaves="3" stitchTiles="stitch"/><feColorMatrix type="saturate" values="0"/><feComponentTransfer><feFuncA type="linear" slope="0.4"/></feComponentTransfer></filter><rect width="100%" height="100%" filter="url(%23film-grain)" /></svg>');
  background-size: 200px 200px;
  background-repeat: repeat;
}

.mobile-hero-section-background::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 51;
  mix-blend-mode: normal;
  opacity: 0.2;
  background: none;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none"><filter id="film-grain"><feTurbulence type="fractalNoise" baseFrequency="0.9" numOctaves="3" stitchTiles="stitch"/><feColorMatrix type="saturate" values="0"/><feComponentTransfer><feFuncA type="linear" slope="0.4"/></feComponentTransfer></filter><rect width="100%" height="100%" filter="url(%23film-grain)" /></svg>');
  background-size: 200px 200px;
  background-repeat: repeat;
}